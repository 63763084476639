
.table-row {
  background-color: #ffffff;
}

.table-row-hidden {
  color: #999;
  /* background-color: #efefef; */
}

.table-parent-row {
  color: #0f4c81;
  /* background-color: #ffffdf; */
}

.table-parent-row-winner {
  color: #0f4c81;
  background-color: #e6f7ff;
}

.ant-table-thead .ant-table-cell {
  font-weight: bold;
  color: #0f4c81;
}

/* .ant-table-tbody{
  padding: 0 !important;
}

.ant-table-tbody > tr > td{
  padding: 0 !important;
} */