/* .editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

.editable-row-co {
  background-color: #fffce0;
}

.editable-row-co:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
} */

.parentTable
  table
  tbody
  .ant-table-expanded-row.ant-table-expanded-row-level-1
  > td {
  padding: 0px !important;
}

.nestedTable > div > div > div {
  width: 100%;
  margin-left: 0px !important;
  margin-right: 0px !important;
}
